import React, { Component } from "react";
import { graphql } from "gatsby";
import styled, { css } from "styled-components";
import Layout from "../components/layout";
import GatsbyImage from "gatsby-image";
import shuffle from "../utils/shuffle";

export default class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: []
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { customiseImages } = data;
    const { edges: images } = customiseImages;
    this.setState({ images: shuffle(images) });
  }

  render() {
    const { data } = this.props;
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    const breadcrumbs = [
      { name: "Home", href: "/" },
      { name: frontmatter.title }
    ];
    return (
      <Layout
        breadcrumbs={breadcrumbs}
        pageTitle={frontmatter.title}
        preambleText={frontmatter.preambleText}
        metaDescription={frontmatter.metaDescription}
      >
        <Content dangerouslySetInnerHTML={{ __html: html }} />
        <Gallery>
          {this.state.images.map(({ node: image }) => (
            <ImageContainer
              key={image.childImageSharp.id}
              aspectRatio={image.childImageSharp.fluid.aspectRatio}
            >
              <StyledGatsbyImage fluid={image.childImageSharp.fluid} />
            </ImageContainer>
          ))}
        </Gallery>
      </Layout>
    );
  }
}

const Content = styled.div`
  max-width: 60ch;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-flow: dense;
  grid-gap: 1rem;

  margin-top: 2rem;
  margin-right: -1rem;
  margin-left: -1rem;
  padding: 0 1rem;

  @media (min-width: 46em) {
    margin-right: -8vw;
    margin-left: -8vw;
  }
`;

const gridSpans = ({ aspectRatio }) => {
  if (aspectRatio > 1.2)
    return css`
      @media (min-width: 46em) {
        grid-column-end: span 2;
      }
    `;
  if (aspectRatio < 0.8)
    return css`
      @media (min-width: 46em) {
        grid-column-end: span 1;
      }
    `;
  return css`
    @media (min-width: 46em) {
      grid-column-end: span 1;
    }
  `;
};

const ImageContainer = styled.div`
  ${gridSpans};
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        preambleText
        metaDescription
      }
    }
    customiseImages: allFile(
      filter: {
        extension: { eq: "jpg" }
        sourceInstanceName: { eq: "cmsCustomiseImages" }
      }
      sort: { fields: size, order: DESC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
    }
  }
`;
